import section2TheBuilderClub from "../assets/section2TheBuildersClub.png";
import section2IndiaToday from "../assets/section2IndiaToday.png";
import section2LuxGuild from "../assets/section2LuxGuild.png";
import section2MbcGroup from "../assets/section2MbcGroup.png";
import section3GraphicDesign from "../assets/4.png";
import section3UiUx from "../assets/1.png";
import section3VideoEditing from "../assets/3.png";
import section3Visualization from "../assets/2.png";
import section8Feat1 from "../assets/section8Feat1.svg";
import section8Feat2 from "../assets/section8Feat2.svg";
import section8Feat3 from "../assets/section8Feat3.svg";
import section8Feat4 from "../assets/section8Feat4.svg";
import section11Feat1 from "../assets/section14Feat1.svg";
import section11Feat2 from "../assets/section14Feat2.svg";
import section11Feat3 from "../assets/section14Feat3.svg";
import section4TailoredIcon from "../assets/section4TailoredIcon.svg";
import section4CommunityIcon from "../assets/section4CommunityIcon.svg";
import section15Img1 from "../assets/section15Img1.png";
import section15Img2 from "../assets/section15Img2.png";
import section15Img3 from "../assets/section15Img3.png";
import section15Img4 from "../assets/section15Img4.png";
import section15Img5 from "../assets/section15Img5.png";
import section15Img6 from "../assets/section15Img6.png";

export const section2Arr = [
  {
    logo: section2TheBuilderClub,
  },
  {
    logo: section2IndiaToday,
  },
  {
    logo: section2MbcGroup,
  },
  {
    logo: section2LuxGuild,
  },
];

export const section3CardsArr = [
  {
    text: "UI/UX Design",
    logo: section3UiUx,
  },
  {
    text: "3d Visualization",
    logo: section3Visualization,
  },
  {
    text: "Video Editing",
    logo: section3VideoEditing,
  },
  {
    text: "Graphic Design",
    logo: section3GraphicDesign,
  },
  
];

export const section6Counts = [
  {
    count: "5K+",
    text: "Projects Closed",
  },
  {
    count: "2K+",
    text: "Clients Onboarded",
  },
];

export const section8FeatureArr = [
  {
    icon: section8Feat1,
    index: "FEATURE 1",
    text: "Robust Support and Conflict Resolution",
  },
  {
    icon: section8Feat2,
    index: "FEATURE 2",
    text: "Efficient Contract based work & payments",
  },
  {
    icon: section8Feat3,
    index: "FEATURE 3",
    text: "Verified and vetted profiles and posts",
  },
  {
    icon: section8Feat4,
    index: "FEATURE 4",
    text: "Skill matching algorithm",
  },
];

export const section9Arr = [
  [{
    step: "1",
    title: "Craft Your Professional Showcase",
    text: "Build a profile that becomes your digital portfolio, highlighting your skills and stellar works to attract potential clients.",
  },
  {
    step: "2",
    title: "Navigate Through Diverse Projects",
    text: "Explore and select from a wide array of opportunities that align with your skills and career aspirations.",
  },
  {
    step: "3",
    title: "Seamless Project Collaboration",
    text: "Engage in smooth project workflows, ensuring efficient collaborations and timely deliveries through Grull’s robust management tools.",
  },
  {
    step: "4",
    title: "Secure Payments and Constructive Reviews",
    text: "Enjoy timely remunerations and share your project experiences, contributing to the continuous enhancement of the Grull community.",
  },],
  [{
    step: "1",
    title: "Craft Your Professional Showcase",
    text: "Build a profile that becomes your digital portfolio, highlighting your skills and stellar works to attract potential clients.",
  },
  {
    step: "2",
    title: "Navigate Through Diverse Projects",
    text: "Explore and select from a wide array of opportunities that align with your skills and career aspirations.",
  },
  {
    step: "3",
    title: "Seamless Project Collaboration",
    text: "Engage in smooth project workflows, ensuring efficient collaborations and timely deliveries through Grull’s robust management tools.",
  },
  {
    step: "4",
    title: "Secure Payments and Constructive Reviews",
    text: "Enjoy timely remunerations and share your project experiences, contributing to the continuous enhancement of the Grull community.",
  },]
];

export const section11FeatureArr = [
  {
    icon: section11Feat1,
    title: "Exclusive Events",
    text: "Participate in events, webinars, and workshops exclusive to the Grull community, enhancing your skills and knowledge.",
  },
  {
    icon: section11Feat2,
    title: "Supportive Network",
    text: "Find support, guidance, and mentorship from a network of experienced freelancers and design experts.",
  },
  {
    icon: section11Feat3,
    title: "Showcase Your Work",
    text: "Gain visibility by showcasing your work, projects, and achievements within the community, opening doors to new opportunities.",
  },
];

export const section16BasicPlan = {
  title: "Basic",
  price: "Free",
  prevPrice: "",
  for: "For freelancers exploring opportunities",
  commission: "10% Commission",
  feat: [
    {
      text: "Access to Grull Marketplace",
      isAvailable: true,
    },
    {
      text: "Recorded Webinars & Workshops",
      isAvailable: true,
    },
    {
      text: "Join Discussions & Activities",
      isAvailable: true,
    },
    {
      text: "Standard Project Matching",
      isAvailable: true,
    },
    {
      text: "Standard Project Matching",
      isAvailable: true,
    },
    {
      text: "Access Recorded Content",
      isAvailable: true,
    },
    {
      text: "Basic Collaboration Features",
      isAvailable: true,
    },
    {
      text: "Earn Certifications & Badges",
      isAvailable: false,
    },
    {
      text: "Connect with Industry Experts",
      isAvailable: false,
    },
    {
      text: "Access Exclusive Networking Events",
      isAvailable: false,
    },
    {
      text: "Personalized Learning Paths",
      isAvailable: false,
    },
    {
      text: "Detailed Performance Analytics",
      isAvailable: false,
    },
  ],
};

export const section16PremiumPlan = {
  title: "Premium",
  price: "₹800",
  prevPrice: "₹1300",
  for: "For freelancers seeking growth and enhanced features",
  commission: "No Commission",
  feat: [
    {
      text: "Priority Access to Grull Marketplace",
      isAvailable: true,
    },
    {
      text: "Live Webinars & Exclusive Workshops",
      isAvailable: true,
    },
    {
      text: "Premium Community Features",
      isAvailable: true,
    },
    {
      text: "Enhanced AI-Powered Recommendations",
      isAvailable: true,
    },
    {
      text: "Dedicated Support Team",
      isAvailable: true,
    },
    {
      text: "Premium Profile Customization",
      isAvailable: true,
    },
    {
      text: "Enhanced Priority Project Matching",
      isAvailable: true,
    },
    {
      text: "Extensive Premium Content Library",
      isAvailable: true,
    },
    {
      text: "Advanced Project Management Tools",
      isAvailable: true,
    },
    {
      text: "Earn Certifications & Badges",
      isAvailable: true,
    },
    {
      text: "Connect with Industry Experts",
      isAvailable: true,
    },
    {
      text: "Access Exclusive Networking Events",
      isAvailable: true,
    },
    {
      text: "Personalized Learning Paths",
      isAvailable: true,
    },
    {
      text: "Detailed Performance Analytics",
      isAvailable: true,
    },
  ],
};

export const footerLinks = {
  LEGAL: [
    {
      link: "/terms-and-conditions",
      text: "Terms & Conditions",
    },
    {
      link: "/policies",
      text: "Privacy Policy",
    },
    {
      link: "/cancellation-policies",
      text: "Cancellation Policy",
    },
    {
      link: "https://496gf0lz.r.ap-south-1.awstrack.me/L0/https:%2F%2Fmerchant.razorpay.com%2Fpolicy%2FOBw0C848KRuqes%2Fshipping/1/0109018f8f8f2386-1627329f-60fa-460e-9df6-b09ffd93888c-000000/55TaY7dR3TR-XVE2P-9XbFp-brg=155",
      text: "Shipping and Delivery Policy",
    },
  ],
  PRODUCTS: [
    {
      link: "/coming-soon",
      text: "Academy",
    },
    {
      link: "/home",
      text: "Marketplace",
    },
    {
      link: "/coming-soon",
      text: "Community",
    },
  ],
  COMPANY: [
    {
      link: "/about-us",
      text: "About Us",
    },
    // {
    //   link: "/",
    //   text: "Press",
    // },
    // {
    //   link: "/",
    //   text: "Blogs",
    // },
    // {
    //   link: "/",
    //   text: "Careers",
    // },
  ],
  // RESOURCES: [
  //   {
  //     link: "/",
  //     text: "Support",
  //   },
  //   {
  //     link: "/",
  //     text: "FAQs",
  //   },
  //   {
  //     link: "/",
  //     text: "Raise a query",
  //   },
  // ],
};

export const section4Cards = [
  {
    icon: section4TailoredIcon,
    title: "Tailored Opportunities",
    text: "Dive into a world where every project is in harmony with your skills and aspirations.",
  },
  {
    icon: section4CommunityIcon,
    title: "Community and Networking",
    text: "Engage, collaborate, and grow with a community that shares your passion and drive.",
  },
];

export const section15ImgArr = [
  {
    img: section15Img1,
  },
  {
    img: section15Img2,
  },
  {
    img: section15Img3,
  },
  {
    img: section15Img4,
  },
  {
    img: section15Img5,
  },
  {
    img: section15Img6,
  },
  {
    img: section15Img2,
  },
  {
    img: section15Img3,
  },
  {
    img: section15Img4,
  }
];
